export default function Logo(): JSX.Element {
  return (
    <svg x="0px" y="0px" viewBox="0 0 1920 720" xmlSpace="preserve">
      <title>Coyote Logistics</title>
      <g>
        <g>
          <path
            d="M326.5,287.7l53.7,33c-28.4,33-80.8,57.8-141.1,57.8c-82.4,0-128.5-41.7-108.6-116c19.9-74.3,88.3-116,173.5-116
			c57.5,0,96.2,25.2,106.5,57.5l-71.6,32.7c-7.8-20.8-25.6-30.8-51.8-30.8c-38.6,0-70.2,20.5-79.9,56.6c-9.7,36.1,11,56.6,49.5,56.6
			C282.9,319.1,306.2,309.7,326.5,287.7z"
          />
          <path
            d="M412,262.5c19.9-74.3,88.9-116,176-116c87.4,0,134.1,41.7,114.2,116c-19.9,74.3-88.9,116-176.3,116
			C438.7,378.5,392.1,336.8,412,262.5z M625,262.5c9.6-35.8-10.6-56.6-52.9-56.6c-42,0-73.7,20.8-83.3,56.6
			c-9.6,35.8,10.6,56.6,52.9,56.6S615.4,298.2,625,262.5z"
          />
          <path d="M800.5,153.6l31.3,86.5l77.3-86.5h83.3L853.7,298.9l-19.4,72.5h-75.9l19.4-72.5l-60.9-145.2H800.5z" />
          <path
            d="M953.6,262.5c19.9-74.3,88.9-116,176-116c87.4,0,134.1,41.7,114.2,116c-19.9,74.3-88.9,116-176.3,116
			C980.4,378.5,933.7,336.8,953.6,262.5z M1166.6,262.5c9.6-35.8-10.6-56.6-52.9-56.6c-42,0-73.7,20.8-83.3,56.6
			c-9.6,35.8,10.6,56.6,52.9,56.6S1157,298.2,1166.6,262.5z"
          />
          <path d="M1516.3,211.2h-86.1l-42.9,160.2h-75.9l42.9-160.2h-85.8l15.4-57.5h247.9L1516.3,211.2z" />
          <path
            d="M1753.9,316.6l-14.7,54.7h-239.2l58.3-217.7h235.7l-14.7,54.7h-161.4l-7.4,27.7h131.5l-13.5,50.4h-131.5l-8.1,30.2H1753.9
			z"
          />
        </g>
        <g>
          <path d="M437.7,547.1l-6.1,22.8h-108l30.9-115.4h26.4l-24.8,92.7H437.7z" />
          <path
            d="M480.7,512.2c10.3-38.6,44.8-61.3,89.8-61.3s67.3,22.8,57,61.3c-10.3,38.6-44.8,61.3-89.8,61.3
			C492.6,573.5,470.3,550.8,480.7,512.2z M600.6,512.2c6.6-24.7-7.6-38.6-36.2-38.6c-28.5,0-50.2,13.9-56.8,38.6
			c-6.6,24.7,7.5,38.6,36.2,38.6C572.2,550.8,593.9,536.9,600.6,512.2z"
          />
          <path
            d="M801.4,546.5c-15.7,15.7-41.6,27-72.9,27c-46.3,0-68.4-22.8-58.1-61.3c10.3-38.6,44.6-61.3,91-61.3
			c28.4,0,45.6,10.7,54.2,21.9l-24.6,15c-7.4-9.9-17.9-14.5-35.4-14.5c-30.2,0-51.4,13.2-58.3,38.9c-6.9,25.7,7.7,38.9,38.4,38.9
			c17.3,0,32.5-3.8,43.4-13.7l3-11.2h-43.5l5.7-21.4h68.3L801.4,546.5z"
          />
          <path d="M855.2,569.9l30.9-115.4h26.4l-30.9,115.4H855.2z" />
          <path
            d="M1079,534.9c-6.1,22.8-31.9,38.6-72.8,38.6c-29.2,0-50.7-8.2-64.4-23.3l20-18c10.7,13,27.4,19.1,50.9,19.1
			c23.4,0,36.5-4.5,39-14c2.6-9.6-8.6-12.2-34.5-14.5c-32.2-2.8-61-10.1-54.3-34.8c6.5-24.1,35.7-37.3,71.3-37.3
			c25.2,0,45.3,6.3,57,17.6l-19.4,18.3c-10.1-9.7-24.7-13.7-43.5-13.9c-15.6-0.2-35.5,2.6-38.3,13.2c-2.7,9.9,13.6,10.9,36,12.7
			C1062,501.8,1085.8,509.5,1079,534.9z"
          />
          <path d="M1260.2,477.2h-50.5l-24.8,92.7h-26.4l24.8-92.7h-50.1l6.1-22.8h127L1260.2,477.2z" />
          <path d="M1289.7,569.9l30.9-115.4h26.4l-30.9,115.4H1289.7z" />
          <path
            d="M1495.3,532l20.9,12c-15.3,16.3-41.1,29.5-71.2,29.5c-43.4,0-65.4-22.9-55.1-61.3c10.3-38.4,44.6-61.3,89.2-61.3
			c29,0,47.7,13.4,54,29.4l-27.5,12c-4.8-12.9-15.8-18.6-32.9-18.6c-27.4,0-49.1,13.2-55.9,38.6c-6.8,25.4,7.8,38.6,35.2,38.6
			C1468.9,550.8,1483.1,545.2,1495.3,532z"
          />
          <path
            d="M1692.3,534.9c-6.1,22.8-31.9,38.6-72.8,38.6c-29.2,0-50.7-8.2-64.4-23.3l20-18c10.7,13,27.4,19.1,50.9,19.1
			c23.4,0,36.5-4.5,39-14c2.6-9.6-8.6-12.2-34.5-14.5c-32.2-2.8-61-10.1-54.3-34.8c6.5-24.1,35.7-37.3,71.3-37.3
			c25.2,0,45.3,6.3,57,17.6l-19.4,18.3c-10.1-9.7-24.7-13.7-43.5-13.9c-15.6-0.2-35.5,2.6-38.3,13.2c-2.7,9.9,13.6,10.9,36,12.7
			C1675.2,501.8,1699.1,509.5,1692.3,534.9z"
          />
        </g>
      </g>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}