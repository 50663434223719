export default function Logo(): JSX.Element {
  return (
    <svg width="123" height="32" viewBox="0 0 123 32" xmlns="http://www.w3.org/2000/svg">
      <title>Philips</title>
      <g clipPath="url(#clip0_71_37406)">
        <mask id="mask0_71_37406" maskUnits="userSpaceOnUse" x="-99" y="-79" width="318" height="218">
          <path d="M-98.3789 -78.3242H218.183V138.214H-98.3789V-78.3242Z" />
        </mask>
        <path d="M42.166 5.76122H35.7046V13.9005H28.9785V5.76122H22.5171V26.239H28.9785V18.1841H35.7046V26.239H42.166V5.76122ZM64.6425 22.1243V5.76122H58.1811V26.239H69.8645L70.8362 22.1243H64.6425ZM53.4042 5.76122H46.9428V26.239H53.4042V5.76122ZM79.5987 5.76122H73.1404V26.239H79.5987V5.76122ZM111.454 10.9876C111.454 9.87308 112.607 9.35801 114.463 9.35801C116.499 9.35801 118.713 9.78869 120.217 10.3911L119.688 5.93291C118.003 5.58953 116.499 5.33345 113.759 5.33345C108.537 5.33345 104.996 7.32682 104.996 11.5027C104.996 18.6992 115.088 17.2442 115.088 20.5849C115.088 21.8682 114.111 22.552 111.722 22.552C109.601 22.552 106.323 21.8682 104.376 20.9253L105.086 25.6396C107.033 26.3263 109.686 26.6668 111.812 26.6668C117.209 26.6668 121.634 24.9528 121.634 19.8108C121.634 12.9606 111.454 14.2439 111.454 10.9876ZM93.2284 21.356C99.2416 21.356 103.05 18.3558 103.05 13.1294C103.05 7.39084 98.9799 5.33345 92.341 5.33345C89.7751 5.33345 86.6768 5.50514 84.4658 5.76122V26.239H90.9272V21.356H93.2284ZM96.6787 13.2167C96.6787 15.8706 95.3521 17.6719 91.9861 17.6719H90.9272V9.2096C91.3032 9.18923 91.6552 9.18922 91.9861 9.18922C95.1716 9.18922 96.6787 10.6442 96.6787 13.2167ZM10.1298 21.356C16.146 21.356 19.9512 18.3558 19.9512 13.1294C19.9512 7.39084 15.8812 5.33345 9.24539 5.33345C6.67648 5.33345 3.58115 5.50514 1.36719 5.76122V26.239H7.82858V21.356H10.1298ZM13.5831 13.2167C13.5831 15.8706 12.2535 17.6719 8.89044 17.6719H7.82858V9.2096C8.20459 9.18923 8.55955 9.18922 8.89044 9.18922C12.076 9.18922 13.5831 10.6442 13.5831 13.2167Z" />
      </g>
      <defs>
        <clipPath id="clip0_71_37406">
          <rect width="123" height="32" />
        </clipPath>
      </defs>
    </svg>
  )
}

try{ Logo.displayName ||= 'Logo' } catch {}